import {
  extendVariants,
  LinkProps as NextUILinkProps,
  useLink,
} from "@nextui-org/react";
import clsx from "clsx";
import { cloneElement, forwardRef, isValidElement, ReactNode } from "react";

interface BaseLinkProps
  extends Omit<NextUILinkProps, "showAnchorIcon" | "anchorIcon"> {
  /**
   * The icon to display after the text.
   */
  endIcon?: React.ReactNode;
  /**
   * The icon to display before the text.
   */
  startIcon?: React.ReactNode;
}

export type LinkProps = React.ComponentProps<typeof Link>;

const BaseLink = forwardRef<HTMLAnchorElement, BaseLinkProps>(
  ({ color, endIcon, startIcon, ...props }, ref) => {
    const getIconClone = (icon: ReactNode) =>
      isValidElement(icon)
        ? cloneElement(icon, {
            // @ts-ignore
            "aria-hidden": true,
            height: "1em",
            width: "1em",
            stroke: "currentColor",
            className: clsx({
              "mx-1 flex self-center leading-none text-current": true, // base classes
              "group-hover:text-primary-dark": color === "primary",
              "group-hover:text-secondary-dark": color === "secondary",
              "group-hover:text-success-dark": color === "success",
              "group-hover:text-warning-dark": color === "warning",
              "group-hover:text-danger-dark": color === "danger",
              "group-visited:text-accent-dark": props.isExternal,
            }),
          })
        : null;

    const startContent = getIconClone(startIcon);
    const endContent = getIconClone(endIcon);

    const { children, Component, getLinkProps } = useLink({
      ...props,
      color,
      className: clsx({
        "group rounded font-bold hover:underline hover:opacity-100": true, // base classes
        "visited:text-accent-dark": props.isExternal,
        [props.className ?? ""]: true, // overwrite classes
      }),
      ref,
    });

    return (
      <Component {...getLinkProps()}>
        <>
          {startContent}
          {children}
          {endContent}
        </>
      </Component>
    );
  },
);

BaseLink.displayName = "RecareUI.BaseLink";

export const Link = extendVariants(BaseLink, {
  variants: {
    color: {
      primary: "text-primary",
      "primary-dark": "text-primary-dark",
      secondary: "text-secondary",
      success: "text-success",
      warning: "text-warning",
      danger: "text-danger",
    },
  },
});
Link.displayName = "RecareUI.Link";
