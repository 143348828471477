import { BannerAction, TourElement } from "core/types";
import {
  CheckCircleIcon,
  CircleSlashIcon,
  CloseIcon,
  ErrorIcon,
  InfoIcon,
} from "ds/icons";
import { Button } from "ds/ui";
import { useFocusElement } from "dsl/hooks/useFocusElement";
import { ElementType, forwardRef, HTMLAttributes, useState } from "react";
import { tv, type VariantProps } from "tailwind-variants";
import { useTranslations } from "translations";

type BannerVariants = VariantProps<typeof bannerVariants>;
type BannerColor = Exclude<BannerVariants["color"], undefined>;

export type BannerProps = HTMLAttributes<HTMLDivElement> & {
  actions?: BannerAction[];
  heading?: string;
  message: React.ReactNode | string;
  messageTour?: TourElement;
  noIcon?: boolean;
  onClose?: (e?: any) => void;
  withClose?: boolean;
} & BannerVariants;

const DEFAULT_COLOR = "primary";
const ICONS: Record<BannerColor, ElementType> = {
  success: CheckCircleIcon,
  danger: CircleSlashIcon,
  warning: ErrorIcon,
  primary: InfoIcon,
};

const bannerVariants = tv({
  slots: {
    wrapper:
      "flex flex-row rounded-lg border-1 border-solid border-gray-500 p-4 space-between w-full items-center gap-3",
    icon: "",
    bannerActions: "ml-3 flex items-center gap-6 overflow-visible",
    bannerContent: "flex flex-col flex-1 gap-2",
    bannerheading: "font-semibold",
    bannerMessage: "text-body-md",
  },
  variants: {
    color: {
      success: {
        wrapper: "bg-success-light",
        icon: "text-success-dark",
      },
      danger: { wrapper: "bg-danger-light", icon: "text-danger-dark" },
      primary: {
        wrapper: "bg-primary-light",
        icon: "text-primary",
      },
      warning: {
        wrapper: "bg-warning-light",
        icon: "text-warning-dark",
      },
    },
    size: {
      sm: {
        icon: "h-4 w-4",
      },
      md: {
        icon: "h-5 w-5",
      },
    },
  },
  defaultVariants: {
    color: DEFAULT_COLOR,
    size: "md",
  },
});

const BannerCloseIcon = ({
  actions,
  onClose,
  setOpen,
  withClose,
}: Pick<BannerProps, "onClose" | "withClose" | "actions"> & {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const firstFocusableElement = useFocusElement<HTMLButtonElement>({
    dependencies: [withClose],
    shouldFocus: !!withClose && !actions?.length,
  });
  const translations = useTranslations();

  if (!withClose && !onClose) return null;

  return (
    <Button
      id="banner-close"
      isIconOnly
      aria-label={translations.actions.close}
      onPress={(e) => {
        setOpen(false);
        onClose?.(e);
      }}
      ref={firstFocusableElement}
      size="xs"
      color="black"
      variant="light"
    >
      <CloseIcon />
    </Button>
  );
};

const BannerAdditionalActions = ({
  actions,
  color,
  withClose,
}: Pick<BannerProps, "withClose" | "actions" | "color">) => {
  const firstFocusableElement = useFocusElement<HTMLButtonElement>({
    dependencies: [withClose],
    shouldFocus: !!actions,
  });

  if (!actions?.length) return null;

  return (
    <>
      {actions.map(({ id, label, onClick, tour }, index) => (
        <span key={label} {...tour}>
          <Button
            id={id}
            onPress={onClick}
            ref={index === 0 ? firstFocusableElement : undefined}
            variant="solid"
            color={color}
            size="sm"
          >
            {label}
          </Button>
        </span>
      ))}
    </>
  );
};

const BannerActions = ({
  actions,
  className,
  color,
  onClose,
  setOpen,
  withClose,
}: Pick<BannerProps, "actions" | "onClose" | "withClose" | "color"> & {
  className: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  if (!actions?.length && !withClose && !onClose) return null;

  return (
    <div className={className}>
      <BannerAdditionalActions actions={actions} color={color} />
      <BannerCloseIcon
        actions={actions}
        onClose={onClose}
        setOpen={setOpen}
        withClose={withClose}
      />
    </div>
  );
};

const BannerIcon = ({
  color = DEFAULT_COLOR,
  noIcon,
  className,
}: Pick<BannerProps, "noIcon" | "color"> & { className: string }) => {
  const Icon = ICONS[color];

  if (noIcon) return null;

  return <Icon className={className} />;
};

export const Banner = forwardRef<HTMLDivElement, BannerProps>(
  (
    {
      actions,
      color = "primary",
      heading,
      message,
      messageTour,
      noIcon,
      onClose,
      size,
      withClose,
      ...rest
    },
    ref,
  ) => {
    const [open, setOpen] = useState(true);
    const {
      bannerActions,
      bannerContent,
      bannerheading,
      bannerMessage,
      icon,
      wrapper,
    } = bannerVariants({
      color,
      size,
    });

    if (!open) return null;

    const Container = typeof message === "string" ? "p" : "div";

    return (
      <div ref={ref} {...rest} className={wrapper()}>
        <BannerIcon className={icon()} color={color} noIcon={noIcon} />
        <div className={bannerContent()}>
          {heading && <p className={bannerheading()}>{heading}</p>}
          <Container className={bannerMessage()} {...messageTour}>
            {message}
          </Container>
        </div>
        <BannerActions
          actions={actions}
          className={bannerActions()}
          color={color}
          onClose={onClose}
          setOpen={setOpen}
          withClose={withClose}
        />
      </div>
    );
  },
);

Banner.displayName = "RecareUI.Banner";
